import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/layout.js";
import icRocks from "../../../images/dfinity/ic-rocks-identity.png";
import { BlogImage } from "../../../components/BlogComponents";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Candid is a very useful part of the Internet Computer ecosystem. It is the interface that allows Canisters to talk to each other and for agents to make calls to Canisters. The default workflow we currently show you in our tutorials is basically "write Motoko code and we'll make the rest happen magically for you using dfx". However, once you've strayed off the beaten path a bit, things have probably gotten more complicated. The goal of this post is to break down how to work with Candid for your application.`}</p>
    <h2 {...{
      "id": "working-from-a-did-file"
    }}><a parentName="h2" {...{
        "href": "#working-from-a-did-file"
      }}>{`Working from a .did file`}</a></h2>
    <p>{`There are situations where you may have a Candid `}<inlineCode parentName="p">{`.did`}</inlineCode>{` file that you want to work with, but don't have the source code available. This could happen for a couple reasons - the service may be an existing canister, already running on the Internet Computer mainnet. Alternately, you could be working on a project where the backend is being developed separately, and you need to work on your portion of the code based on an agreed-upon `}<inlineCode parentName="p">{`.did`}</inlineCode>{` file.`}</p>
    <h2 {...{
      "id": "setting-up"
    }}><a parentName="h2" {...{
        "href": "#setting-up"
      }}>{`Setting up`}</a></h2>
    <p>{`To illustrate this, I'll create an example project. `}</p>
    <pre {...{
      "className": "language-shell-session"
    }}><code parentName="pre" {...{
        "className": "language-shell-session"
      }}><span parentName="code" {...{
          "className": "token output"
        }}>{`dfx new no_backend
cd no_backend
`}</span></code></pre>
    <p>{`Now, inside my project, I'll remove `}<inlineCode parentName="p">{`src/no_backend`}</inlineCode>{` and clean up `}<inlineCode parentName="p">{`dfx.json`}</inlineCode>{`. I'll remove the `}<inlineCode parentName="p">{`no_backend`}</inlineCode>{` canister, and I'll remove the `}<inlineCode parentName="p">{`dependencies`}</inlineCode>{` from `}<inlineCode parentName="p">{`no_backend_assets`}</inlineCode>{`.`}</p>
    <pre {...{
      "className": "language-json"
    }}><code parentName="pre" {...{
        "className": "language-json"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// dfx.json`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"canisters"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"no_backend_assets"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
      `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"frontend"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
        `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"entrypoint"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"src/no_backend_assets/src/index.html"`}</span>{`
      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
      `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"source"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span>{`
        `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"src/no_backend_assets/assets"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
        `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"dist/no_backend_assets/"`}</span>{`
      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
      `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"type"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"assets"`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
  `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"defaults"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"build"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
      `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"args"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`""`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
      `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"packtool"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`""`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
  `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"dfx"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"0.8.0"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
  `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"networks"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"local"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
      `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"bind"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"127.0.0.1:8000"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
      `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"type"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"ephemeral"`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
  `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"version"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`1`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
`}</code></pre>
    <p>{`Then, update the webpack environment variable to point to the canister you want to talk to. In this case, we'll always be talking to production, but you can configure different options for different environments based on `}<inlineCode parentName="p">{`DFX_NETWORK`}</inlineCode>{` as you see fit.`}</p>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// webpack.config.js`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`new`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`webpack`}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`EnvironmentPlugin`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token constant"
        }}>{`NODE_ENV`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'development'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    `}<span parentName="code" {...{
          "className": "token constant"
        }}>{`IDENTITY_CANISTER_ID`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"rdmx6-jaaaa-aaaaa-aaadq-cai"`}</span>{` `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// production Internet Identity canister`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
`}</code></pre>
    <p>{`Now, I'll need to find a candid interface I want to interact with. For now, I'll go with the Internet Identity canister. I can find my interface by going to `}<a parentName="p" {...{
        "href": "https://ic.rocks/canisters"
      }}>{`ic.rocks/canisters`}</a>{`, and selecting the NNS subnet, System tdb26.`}</p>
    <BlogImage src={icRocks} mdxType="BlogImage">
    IC Rocks canister inspection view
    </BlogImage>
    <p>{`Then, after clicking on the `}<a parentName="p" {...{
        "href": "https://ic.rocks/principal/rdmx6-jaaaa-aaaaa-aaadq-cai"
      }}>{`Identity canister`}</a>{`, I can scroll down and see the Candid interface of the Internet Identity canister. I'll copy this file, and add it under `}<inlineCode parentName="p">{`src/identity`}</inlineCode>{` as a new file, `}<inlineCode parentName="p">{`identity.did`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "generating-types-using-dfx"
    }}><a parentName="h2" {...{
        "href": "#generating-types-using-dfx"
      }}>{`Generating types using DFX`}</a></h2>
    <pre {...{
      "className": "language-typescript"
    }}><code parentName="pre" {...{
        "className": "language-typescript"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// src/declarations/identity.did`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`type`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}><span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`UserNumber`}</span></span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` nat64`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`type`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}><span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`PublicKey`}</span></span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` blob`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`type`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}><span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`CredentialId`}</span></span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` blob`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`type`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}><span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`DeviceKey`}</span></span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`PublicKey`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`type`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}><span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`UserKey`}</span></span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`PublicKey`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`type`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}><span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`SessionKey`}</span></span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`PublicKey`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`type`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}><span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`FrontendHostname`}</span></span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` text`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`type`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}><span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`Timestamp`}</span></span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` nat64`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token spread operator"
        }}>{`...`}</span>{`

service `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`opt `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`InternetIdentityInit`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`-`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  init_salt`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`-`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  register `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`DeviceData`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`ProofOfWork`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`-`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`RegisterResponse`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  add `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`UserNumber`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`DeviceData`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`-`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  remove `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`UserNumber`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`DeviceKey`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`-`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  lookup `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`UserNumber`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`-`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`vec `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`DeviceData`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` query`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  get_principal `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`UserNumber`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`FrontendHostname`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`-`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`principal`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` query`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  stats `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`-`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`InternetIdentityStats`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` query`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  prepare_delegation `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`UserNumber`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`FrontendHostname`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`SessionKey`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` maxTimeToLive `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` opt nat64`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`-`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`UserKey`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Timestamp`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  get_delegation`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`UserNumber`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`FrontendHostname`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`SessionKey`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Timestamp`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`-`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`GetDelegationResponse`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` query`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  http_request`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`request`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`HttpRequest`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`-`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`HttpResponse`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` query`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`

`}</code></pre>
    <p>{`I'll also create an empty `}<inlineCode parentName="p">{`identity.wasm`}</inlineCode>{` file under `}<inlineCode parentName="p">{`src/identity`}</inlineCode>{`. This will be important for the next step.`}</p>
    <p>{`With the interface in place, I can have `}<inlineCode parentName="p">{`dfx`}</inlineCode>{` generate declarations for the Identity canister by creating a placeholder canister. `}</p>
    <pre {...{
      "className": "language-json"
    }}><code parentName="pre" {...{
        "className": "language-json"
      }}><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"canisters"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"identity"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
      `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"candid"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"src/identity/identity.did"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
      `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"type"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"custom"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
      `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"wasm"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"src/identity/identity.wasm"`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    ...
`}</code></pre>
    <p>{`This configuration tells `}<inlineCode parentName="p">{`dfx`}</inlineCode>{` that there is a custom canister, with a `}<inlineCode parentName="p">{`.did`}</inlineCode>{` file and the empty wasm, which will allow it to generate the types for you. To have `}<inlineCode parentName="p">{`dfx`}</inlineCode>{` generate your types, run the following commands:`}</p>
    <pre {...{
      "className": "language-shell-session"
    }}><code parentName="pre" {...{
        "className": "language-shell-session"
      }}><span parentName="code" {...{
          "className": "token output"
        }}>{`dfx canister create no_backend_assets
dfx canister create identity
dfx build
npm run copy:types
`}</span></code></pre>
    <p>{`Now, your canister should have created types for the `}<inlineCode parentName="p">{`no_backend_assets`}</inlineCode>{` and `}<inlineCode parentName="p">{`identity`}</inlineCode>{` canisters and copied them into your `}<inlineCode parentName="p">{`src/declarations`}</inlineCode>{` folder. You can git commit those files, and now all that remains is to update `}<inlineCode parentName="p">{`webpack.config.js`}</inlineCode>{` to point to the live Identity canister.`}</p>
    <p>{`Now, `}<inlineCode parentName="p">{`src/declarations/identity/index.js`}</inlineCode>{` has automatically generated your types. If you are done with the canister, you can delete the code out of `}<inlineCode parentName="p">{`dfx.json`}</inlineCode>{` and continue on with local development.`}</p>
    <h2 {...{
      "id": "generating-types-using-didc"
    }}><a parentName="h2" {...{
        "href": "#generating-types-using-didc"
      }}>{`Generating Types using didc`}</a></h2>
    <p>{`So, that method is messy. It's convenient, in that you don't need to install any additional software. That said, if you are willing to install our Candid type generation tool, `}<inlineCode parentName="p">{`didc`}</inlineCode>{`, you can have a simpler setup.`}</p>
    <h3 {...{
      "id": "installing-didc"
    }}><a parentName="h3" {...{
        "href": "#installing-didc"
      }}>{`Installing didc`}</a></h3>
    <p>{`You can go to `}<a parentName="p" {...{
        "href": "https://github.com/dfinity/candid/releases"
      }}>{`https://github.com/dfinity/candid/releases`}</a>{` and install `}<inlineCode parentName="p">{`didc`}</inlineCode>{` for your environment. `}<inlineCode parentName="p">{`didc-macos`}</inlineCode>{` for Mac and `}<inlineCode parentName="p">{`didc-linux64`}</inlineCode>{` for linux. If you save it as `}<inlineCode parentName="p">{`didc`}</inlineCode>{`, you can add it to your PATH in `}<inlineCode parentName="p">{`~/bin`}</inlineCode>{`, with an alias, or whatever your preference is.`}</p>
    <p>{`I have also written a shell script to automatically install the latest build of `}<inlineCode parentName="p">{`didc`}</inlineCode>{` for you:`}</p>
    <pre {...{
      "className": "language-shell-session"
    }}><code parentName="pre" {...{
        "className": "language-shell-session"
      }}><span parentName="code" {...{
          "className": "token output"
        }}>{`unameOut="$(uname -s)"
case "\${unameOut}" in
    Linux*)     machine=Linux;;
    Darwin*)    machine=Mac;;
    *)          machine="UNKNOWN:\${unameOut}"
esac

release=$(curl --silent "https://api.github.com/repos/dfinity/candid/releases/latest" | grep -e '"tag_name"' | cut -c 16-25)

if [ \${machine} = "Mac" ]
then
  echo "Downloading didc for Mac to ~/bin/didc"
  curl -fsSL https://github.com/dfinity/candid/releases/download/\${release}/didc-macos > ~/bin/didc
elif [ \${machine} = "Linux" ]
then
  echo "Downloading didc for Linux to ~/bin/didc"
  curl -fsSL https://github.com/dfinity/candid/releases/download/\${release}/didc-linux64 > ~/bin/didc
else
  echo "Could not detect a supported operating system. Please note that didc is currently only supported for Mac and Linux"
fi

date

`}</span></code></pre>
    <p>{`If you run this, you should have didc downloaded and saved to `}<inlineCode parentName="p">{`~/bin/didc`}</inlineCode>{` automatically. `}</p>
    <h3 {...{
      "id": "using-didc-to-generate-types"
    }}><a parentName="h3" {...{
        "href": "#using-didc-to-generate-types"
      }}>{`Using didc to generate types`}</a></h3>
    <p>{`If we back up to the end of `}<a href="#setting-up">{`setting up`}</a>{`, we can bypass using `}<inlineCode parentName="p">{`dfx`}</inlineCode>{` to generate the types and just generate them ourselves.`}</p>
    <p>{`In `}<inlineCode parentName="p">{`package.json`}</inlineCode>{`, we can add three scripts: `}</p>
    <pre {...{
      "className": "language-json"
    }}><code parentName="pre" {...{
        "className": "language-json"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// package.json`}</span>{`
`}<span parentName="code" {...{
          "className": "token property"
        }}>{`"generate:types"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"npm run generate:idl; npm run generate:ts"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
`}<span parentName="code" {...{
          "className": "token property"
        }}>{`"generate:idl"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"didc bind ./src/identity.did -t js > src/declarations/identity/identity.did.js"`}</span>{`
`}<span parentName="code" {...{
          "className": "token property"
        }}>{`"generate:ts"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"didc bind ./src/identity.did -t ts > src/declarations/identity/identity.did.d.ts"`}</span>{`
`}</code></pre>
    <p>{`Now, running `}<inlineCode parentName="p">{`npm run generate:types`}</inlineCode>{` will generate both an IDL interface and a set of typescript declarations, just like `}<inlineCode parentName="p">{`dfx`}</inlineCode>{` was doing for us.`}</p>
    <h3 {...{
      "id": "writing-a-custom-index"
    }}><a parentName="h3" {...{
        "href": "#writing-a-custom-index"
      }}>{`Writing a custom index`}</a></h3>
    <p>{`Next, we need to add an index file for our declarations, mimicking the one that `}<inlineCode parentName="p">{`dfx`}</inlineCode>{` auto-generates. If you are using a different name for the canister you are talking to other than `}<inlineCode parentName="p">{`identity`}</inlineCode>{`, replace all instances of it in your code. `}</p>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// src/declarations/identity/index.js`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`Actor`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`HttpAgent`}</span>{` `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`}`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"@dfinity/agent"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Imports and re-exports candid interface`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` idlFactory `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`}`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'./identity.did.js'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`export`}</span>{` `}<span parentName="code" {...{
          "className": "token exports"
        }}><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` idlFactory `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`}`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'./identity.did.js'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// CANISTER_ID is replaced by webpack based on node environment`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`export`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` canisterId `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` process`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`env`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token constant"
        }}>{`IDENTITY_CANISTER_ID`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token doc-comment comment"
        }}>{`/**
 * 
 * `}<span parentName="span" {...{
            "className": "token keyword"
          }}>{`@param`}</span>{` `}<span parentName="span" {...{
            "className": "token class-name"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`string `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword module"
            }}>{`import`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`"@dfinity/principal"`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`Principal`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{` `}<span parentName="span" {...{
            "className": "token parameter"
          }}>{`canisterId`}</span>{` Canister ID of Agent
 * `}<span parentName="span" {...{
            "className": "token keyword"
          }}>{`@param`}</span>{` `}<span parentName="span" {...{
            "className": "token class-name"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`agentOptions`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword module"
            }}>{`import`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`"@dfinity/agent"`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`HttpAgentOptions`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{` actorOptions`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword module"
            }}>{`import`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`"@dfinity/agent"`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`ActorConfig`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{` `}<span parentName="span" {...{
            "className": "token optional-parameter"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="span" {...{
              "className": "token parameter"
            }}>{`options`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`]`}</span></span>{`
 * `}<span parentName="span" {...{
            "className": "token keyword"
          }}>{`@return`}</span>{` `}<span parentName="span" {...{
            "className": "token class-name"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "token keyword module"
            }}>{`import`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`"@dfinity/agent"`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`ActorSubclass`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token keyword module"
            }}>{`import`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`"./identity.did.js"`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`_SERVICE`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`
 */`}</span>{`
 `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`export`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token function-variable function"
        }}>{`createActor`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`canisterId`}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` options`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` agent `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`new`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`HttpAgent`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` `}<span parentName="code" {...{
          "className": "token spread operator"
        }}>{`...`}</span>{`options`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`?.`}</span>{`agentOptions `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  
  `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Fetch root key for certificate validation during development`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`if`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`process`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`env`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token constant"
        }}>{`NODE_ENV`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`!==`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"production"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    agent`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`fetchRootKey`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`catch`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`err`}</span><span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
      `}<span parentName="code" {...{
          "className": "token console class-name"
        }}>{`console`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`warn`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"Unable to fetch root key. Check to ensure that your local replica is running"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
      `}<span parentName="code" {...{
          "className": "token console class-name"
        }}>{`console`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`error`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`err`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`

  `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Creates an actor with using the candid interface and the HttpAgent`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`return`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Actor`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`createActor`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`idlFactory`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    agent`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    canisterId`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    `}<span parentName="code" {...{
          "className": "token spread operator"
        }}>{`...`}</span>{`options`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`?.`}</span>{`actorOptions`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  
`}<span parentName="code" {...{
          "className": "token doc-comment comment"
        }}>{`/**
 * A ready-to-use agent for the identity canister
 * `}<span parentName="span" {...{
            "className": "token keyword"
          }}>{`@type`}</span>{` `}<span parentName="span" {...{
            "className": "token class-name"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "token keyword module"
            }}>{`import`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`"@dfinity/agent"`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`ActorSubclass`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token keyword module"
            }}>{`import`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`"./identity.did.js"`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`_SERVICE`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`
 */`}</span>{`
 `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`export`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` identity `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`createActor`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`canisterId`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}</code></pre>
    <p>{`And with that, you've done all the magic that `}<inlineCode parentName="p">{`dfx`}</inlineCode>{` was doing for you! Your new file imports the IDL and types and exports an `}<inlineCode parentName="p">{`identity`}</inlineCode>{` actor as well as a `}<inlineCode parentName="p">{`createActor`}</inlineCode>{` utility method. I hope this serves as a useful reference!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      